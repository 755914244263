import React from "react";
import { IconContext } from "react-icons";
import { FaInstagram, FaTwitter } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <section className="pb-3">
      <div className="container">
        <div className="row mb__5 sm-2">
          <div className="mb-5 mb-md-0 col-md-2 text-center text-md-left">
            <span style={{ fontSize: "25px" }}>
              <img
                src="./../LogoBlackLetters.svg"
                alt=""
                style={{ maxHeight: "50px", width: "auto" }}
              />
            </span>
          </div>
          <div className="mb-4 mb-md-0 col-md-5">
            <div className="row">
              <div className="col-4">
                <h5 className="semi-bold font__size--14 text__14-1024 mb-3"></h5>
                <ul className="list__foooter-menu">
                  <li>
                    <NavLink
                      to="/"
                      className="medium font__size--14 text__14-1024 color__gray-1"
                    >
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/news"
                      className="medium font__size--14 text__14-1024 color__gray-1"
                    >
                      News
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="col-4">
                <h5 className="semi-bold font__size--14 text__14-1024 mb-3"></h5>
                <ul className="list__foooter-menu">
                  <li>
                    <NavLink
                      to="/privacy"
                      className="medium font__size--14 text__14-1024 color__gray-1"
                    >
                      Privacy Notice
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/terms"
                      className="medium font__size--14 text__14-1024 color__gray-1"
                    >
                      Terms of Service
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="mb-4 mb-md-0 col-6 col-md">
            <h5 className="semi-bold font__size--14 text__14-1024 mb-3">
              Connect with us
            </h5>
            <ul className="list__foooter-sosmed">
              <li>
                <a
                  href="https://twitter.com/solwinio"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconContext.Provider
                    value={{ color: "#8682E3", size: "2em" }}
                  >
                    <div>
                      <FaTwitter />
                    </div>
                  </IconContext.Provider>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/solwinio"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconContext.Provider
                    value={{ color: "#8682E3", size: "2em" }}
                  >
                    <div>
                      <FaInstagram />
                    </div>
                  </IconContext.Provider>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="text-center medium font__size--14 text__14-1024">
          © 2024 SolWin Inc
        </div>
      </div>
    </section>
  );
};

export default Footer;
