import React, { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";

const Navbar = (props) => {
  const [toogleMenu, setToogleMenu] = useState(false);

  return (
    <Fragment>
      <div
        className={
          "wrapper__side-nav-mobile d-flex d-lg-none " +
          (toogleMenu ? "active" : "")
        }
      >
        <div className="d-flex flex-wrap w-100">
          <div className="w-100">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <NavLink
                to="/"
                exact
                class="navbar-brand font__size--24 text__24-1024 semi-bold color__black"
              >
                <span style={{ fontSize: "40px" }}>
                  <img
                    src="./../LogoBlackLetters.svg"
                    alt=""
                    style={{ maxHeight: "75px", width: "auto" }}
                  />
                </span>
              </NavLink>
              <img
                src="./../images/clsoe2.png"
                onClick={() => setToogleMenu(!toogleMenu)}
                className="pointer"
                alt=""
              />
            </div>
            <div className="menu">
              <NavLink
                to="/news"
                className="medium font__size--14 text__14-1024 color__black"
              >
                News
              </NavLink>
              <hr />
              <NavLink
                to="/faq"
                className="medium font__size--14 text__14-1024 color__black"
              >
                Support
              </NavLink>
              <hr />
              <NavLink
                to="/team"
                className="medium font__size--14 text__14-1024 color__black"
              >
                Team
              </NavLink>
              <hr />
              <div className="medium font__size--14 text__14-1024 btn btn__purple color__white shadow ml-auto text-capitalize rounded__12 px-4 pointer disabled">
                Play Now
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="bg__wrap-menu d-lg-none"
        onClick={() => setToogleMenu(!toogleMenu)}
      ></div>
      <nav
        className="navbar navbar-expand-lg bg__black-2 wrapper__navbar position-relative z-2"
        data-aos="fade-down"
        data-aos-duration="1000"
        data-aos-delay="300"
      >
        <div className="container position-relative">
          <NavLink
            to="/"
            exact
            class="navbar-brand font__size--24 text__24-1024 semi-bold color__black"
          >
            <span style={{ fontSize: "40px" }}>
              <img
                src="./../LogoBlackLetters.svg"
                alt=""
                style={{ maxHeight: "75px", width: "auto" }}
              />
            </span>
          </NavLink>

          <div
            className="collapse navbar-collapse wrapper__navbar-menu ml-lg-5"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav menu__center">
              <li className="nav-item">
                <NavLink
                  to="/news"
                  class="nav-link medium font__size--14 text__14-1024 color__black opacity__7 text-capitalize hover"
                >
                  News
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/faq"
                  class="nav-link medium font__size--14 text__14-1024 color__black opacity__7 text-capitalize hover"
                >
                  Support
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/team"
                  class="nav-link medium font__size--14 text__14-1024 color__black opacity__7 text-capitalize hover"
                >
                  Team
                </NavLink>
              </li>
            </ul>
          </div>

          <div
            onClick={() => setToogleMenu(!toogleMenu)}
            class="nav__button d-lg-none position-relative ml-auto flex-shrink-0"
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className="d-none d-lg-block medium font__size--14 text__14-1024 btn btn__purple color__white shadow ml-auto text-capitalize rounded__12 px-4 pointer disabled">
            Play Now
          </div>
        </div>
      </nav>
    </Fragment>
  );
};

export default Navbar;
