import React, { Fragment } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import DetailNews from "./homepage/DetailNews";
import Faq from "./homepage/Faq";
import News from "./homepage/News";
import Privacy from "./homepage/Privacy";
import ProductDetail from "./homepage/ProductDetail";
import Team from "./homepage/Team";
import Terms from "./homepage/Terms";
import CreateRaffle from "./homepage/createraffle";
import Homepage from "./homepage/index";
import Profile from "./homepage/profile";

const Index = (props) => {
  return (
    <Fragment>
      <Router forceRefresh>
        <Switch>
          <Route exact path="/">
            <Homepage />
          </Route>
          <Route exact path="/team">
            <Team />
          </Route>
          <Route exact path="/product/detail">
            <ProductDetail />
          </Route>
          <Route exact path="/news">
            <News />
          </Route>
          <Route exact path="/news/detail">
            <DetailNews />
          </Route>
          <Route exact path="/faq">
            <Faq />
          </Route>
          <Route exact path="/terms">
            <Terms />
          </Route>
          <Route exact path="/privacy">
            <Privacy />
          </Route>
          <Route exact path="/profile">
            <Profile />
          </Route>
          <Route exact path="/createraffle">
            <CreateRaffle />
          </Route>
        </Switch>
      </Router>
    </Fragment>
  );
};

export default Index;
