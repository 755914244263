import React, { Fragment } from "react";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";
import Head from "../../component/Page/Head";

const sampleProfile = {
  avatar:
    "https://pbs.twimg.com/profile_images/1680568559997444096/kMsr7fun_400x400.jpg",
  solana_account: "BcR9ynaMdAUwBtk6bEuFaCDK4MpJ9eGMGEMra4MVpFPt",
  shippingAddress: "123 Sample Drive, Some City, Some State, Some Country",
  ticketsPurchased: 314,
  rafflesWon: 7,
};

const Profile = ({
  avatar,
  solana_account,
  shippingAddress,
  ticketsPurchased,
  rafflesWon,
}) => {
  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="bg__blue-2">
          <Navbar />
          <Head title={"Player profile"} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px",
            maxWidth: "600px",
            margin: "0 auto",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
            backgroundColor: "#fff",
            fontFamily: "Arial, sans-serif",
          }}
        >
          <img
            src={sampleProfile.avatar}
            alt="User Avatar"
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "50%",
              marginBottom: "20px",
            }}
          />
          <div style={{ textAlign: "center", marginBottom: "10px" }}>
            <div style={{ fontWeight: "bold", marginBottom: "5px" }}>
              Solana account:
            </div>
            <div style={{ color: "#555" }}>
              {String(sampleProfile.solana_account).substring(0, 6) + "...."}
            </div>
          </div>
          <div style={{ textAlign: "center", marginBottom: "10px" }}>
            <div style={{ fontWeight: "bold", marginBottom: "5px" }}>
              Shipping Address:
            </div>
            <div style={{ color: "#555" }}>{sampleProfile.shippingAddress}</div>
          </div>
          <div style={{ textAlign: "center", marginBottom: "10px" }}>
            <div style={{ fontWeight: "bold", marginBottom: "5px" }}>
              Tickets Purchased:
            </div>
            <div style={{ color: "#555" }}>
              {sampleProfile.ticketsPurchased}
            </div>
          </div>
          <div style={{ textAlign: "center", marginBottom: "10px" }}>
            <div style={{ fontWeight: "bold", marginBottom: "5px" }}>
              Raffles Won:
            </div>
            <div style={{ color: "#555" }}>{sampleProfile.rafflesWon}</div>
          </div>
          <button
            className="btn__purple"
            style={{
              padding: "10px 20px",
              fontSize: "16px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              marginTop: "20px",
            }}
          >
            Edit Profile
          </button>
        </div>
        <Footer />
      </div>
    </Fragment>
  );
};

export default Profile;
