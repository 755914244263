import React from "react";
import { NavLink } from "react-router-dom";

const CardBlog = (props) => {
  return (
    <NavLink
      to="/news/detail"
      className="wrapper__card-blog d-inline-block w-100"
    >
      <img
        src={props.data.img}
        alt=""
        className="img mb-3"
        style={{ borderRadius: "25px" }}
      />
      <p className="semi-bold font__size--14 text__14-1024 color__blue mb-0">
        {props.data.sub}
      </p>
      <h4 className="normal font__size--20 text__20-1024 color__black mb-0">
        {props.data.title}
      </h4>
    </NavLink>
  );
};

export default CardBlog;
