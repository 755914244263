import React, { Fragment, useEffect, useState } from "react";
import AccordionFaq from "../../component/Accordion/AccordionFaq";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";
import Head from "../../component/Page/Head";

const Faq = () => {
  const [toogle, setToogle] = useState(-1);

  const toogleFunc = (e) => {
    if (e === toogle) {
      setToogle(-1);
    } else {
      setToogle(e);
    }
  };

  const faqData = [
    {
      title: "How to play SolWin?",
      desc: "Participating in SolWin is very simple. Connect your Solana wallet, select the draw you want to participate in, choose the number of tickets you want to buy, choose the currency you want to pay with (you can do it with SOL or USDC) and click BUY. We make it even easier for you! You can also enter the giveaways directly from our Twitter profile using Solana Blinks!",
      category: "Game guidance",
    },
    {
      title: "The benefits of playing SolWin",
      desc: `Are you passionate about technology? Do you want to get your hands on the latest gadgets without spending a fortune? Then SolWin is for you!
Win the Best Gadgets!
At SolWin, we offer you the chance to win smartphones, tablets, smartwatches, gaming consoles, and much more. Imagine holding the latest tech from top brands, all with a simple entry into our raffles.
Guaranteed Transparency and Security
Trust is our top priority. Thanks to Solana's blockchain technology, all our raffles are completely transparent and auditable. Each raffle is conducted fairly and securely, so you can participate with complete peace of mind. For your security reasons, there is a limit of tickets per wallet
Low Costs and Unmatched Speed
Forget about high fees and long waits. At SolWin, we use the advanced Solana network to provide you with a fast and affordable experience. Entering our raffles is easy, accessible, and efficient.
Prizes Delivered or Cash in Your Wallet
If you win and we can't deliver the prize directly to you, we'll send the equivalent value to your digital wallet. This way, you can buy your favorite gadget at your local store without any hassle.
Join the Tech Revolution
We are excited to share this innovative project with you. At SolWin, we don't just offer prizes; we provide a unique and thrilling experience in the world of technology and cryptocurrencies.
Don't wait any longer! Join SolWin today and discover the excitement of winning the most incredible gadgets on the market.
      `,
      category: "Game guidance",
    },
    {
      title: "What do I need to play?",
      desc: `To join the excitement of SolWin raffles, follow these simple steps:
1. Get a Solana Wallet
To participate in our raffles, you'll need a wallet compatible with the Solana network. Some popular options include Phantom, Backpack and Solflare. These wallets are user-friendly and secure.
2. Add Funds to Your Wallet
Once you have your wallet, you'll need to add some funds to it. Make sure you have enough SOL (Solana) or USDC (USD Coin), as these are required to pay for raffle entries. You can acquire SOL or USDC on various cryptocurrency exchanges and transfer them to your wallet.
3. Connect and Participate
With your wallet ready and funds available, simply connect to our platform, choose the raffle you want to enter, and purchase your tickets. It's that easy!
4. Enjoy the Experience
Participating in SolWin is safe, transparent, and exciting. Thanks to Solana's blockchain technology, you can be confident that all raffles are conducted fairly and are fully auditable.
Get ready to win the best gadgets and enjoy a unique experience with SolWin! If you have any questions, our support team is here to help you every step of the way.`,
      category: "Technical Assistance",
    },
    {
      title: "What is Solana?",
      desc: `
      Solana is a high-performance blockchain platform designed to support decentralized applications (dApps) and crypto-currencies at a massive scale.
      Here are some key features that make Solana stand out:
1. Lightning-Fast Transactions
Solana is known for its incredible speed. The platform can handle over 65,000 transactions per second (TPS), making it one of the fastest blockchains in the world. This means your transactions are processed almost instantly.
2. Low Transaction Costs
Unlike many other blockchain networks, Solana offers extremely low transaction fees. This makes it cost-effective for users to participate in various activities on the network, such as trading, gaming, and more.
3. High Scalability
Solana is built to scale without compromising decentralization or security. Its unique architecture allows it to grow and support a large number of users and applications without slowing down or becoming less secure.
4. Robust Security
Security is a top priority for Solana. The network employs advanced cryptographic techniques and consensus mechanisms to ensure that all transactions are secure and tamper-proof.
5. Developer-Friendly
Solana is designed to be accessible for developers, offering a range of tools and resources to help them build and deploy decentralized applications efficiently.
6. Thriving Ecosystem
Solana has a rapidly growing ecosystem of projects and applications, including DeFi (decentralized finance), NFTs (non-fungible tokens), and more. This vibrant community continually innovates and expands the platform's capabilities.
Why Solana for SolWin?
At SolWin, we leverage Solana's advanced technology to provide you with a seamless and enjoyable experience. The platform's speed, low costs, and security ensure that our raffles are fair, transparent, and efficient. With Solana, you can participate with confidence, knowing you're using one of the most advanced blockchain networks available.
`,
      category: "Technical Assistance",
    },
  ];

  const [selected, setSelected] = useState("All");
  const [showing, setShowing] = useState([]);

  useEffect(() => {
    if (selected !== "All") {
      const data = faqData.filter((item) => item.category === selected);
      setShowing(data);
    } else {
      setShowing(faqData);
    }
  }, [selected]);

  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="bg__blue-2">
          <Navbar />

          <Head title={"FAQ"} />
        </div>
        <img
          src="./../images/Subtract.png"
          className="w-100 img-cover"
          alt=""
        />

        <section>
          <div className="container">
            <div className="wrapper__offside-faq">
              <div className="auto">
                <div className="wrapper__category-faq semi-bold font__size--16 text__16-1024 mb-5">
                  <div
                    className={
                      "items pointer " + (selected === "All" ? "active" : "")
                    }
                    onClick={() => setSelected("All")}
                  >
                    All
                  </div>
                  <div
                    className={
                      "items pointer " +
                      (selected === "Game guidance" ? "active" : "")
                    }
                    onClick={() => setSelected("Game guidance")}
                  >
                    Game guidance
                  </div>
                  <div
                    className={
                      "items pointer " +
                      (selected === "Technical Assistance" ? "active" : "")
                    }
                    onClick={() => setSelected("Technical Assistance")}
                  >
                    Technical Assistance
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                {showing.map((obj, i) => {
                  if (i < showing.length / 2) {
                    return (
                      <div className="mb-4">
                        <AccordionFaq
                          data={obj}
                          id={i}
                          toogle={toogle}
                          toogleFunc={(e) => toogleFunc(e)}
                        />
                      </div>
                    );
                  }
                })}
              </div>
              <div className="col-md-6">
                {showing.map((obj, i) => {
                  if (i >= showing.length / 2) {
                    return (
                      <div className="mb-4">
                        <AccordionFaq
                          data={obj}
                          id={i}
                          toogle={toogle}
                          toogleFunc={(e) => toogleFunc(e)}
                        />
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </Fragment>
  );
};

export default Faq;
