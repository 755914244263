import React, { Fragment } from "react";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";
import Head from "../../component/Page/Head";

const sampleProfile = {
  avatar:
    "https://pbs.twimg.com/profile_images/1680568559997444096/kMsr7fun_400x400.jpg",
  solana_account: "BcR9ynaMdAUwBtk6bEuFaCDK4MpJ9eGMGEMra4MVpFPt",
  shippingAddress: "123 Sample Drive, Some City, Some State, Some Country",
  ticketsPurchased: 314,
  rafflesWon: 7,
};

const CreateRaffle = ({
  avatar,
  solana_account,
  shippingAddress,
  ticketsPurchased,
  rafflesWon,
}) => {
  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="bg__blue-2">
          <Navbar />
          <Head title={"Create Raffle"} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px",
            maxWidth: "600px",
            margin: "0 auto",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
            backgroundColor: "#fff",
            fontFamily: "Arial, sans-serif",
          }}
        >
          <div style={{ marginBottom: "20px" }}>
            <label
              style={{
                display: "block",
                marginBottom: "10px",
                fontWeight: "bold",
              }}
            >
              Upload Picture:
            </label>
            <input type="file" />
          </div>
          <div style={{ width: "100%", marginBottom: "15px" }}>
            <label
              style={{
                display: "block",
                marginBottom: "5px",
                fontWeight: "bold",
              }}
            >
              Item Name:
            </label>
            <input
              type="text"
              style={{ width: "100%", padding: "8px", boxSizing: "border-box" }}
            />
          </div>
          <div style={{ width: "100%", marginBottom: "15px" }}>
            <label
              style={{
                display: "block",
                marginBottom: "5px",
                fontWeight: "bold",
              }}
            >
              Item Description:
            </label>
            <textarea
              style={{ width: "100%", padding: "8px", boxSizing: "border-box" }}
              rows="4"
            />
          </div>
          <div style={{ width: "100%", marginBottom: "15px" }}>
            <label
              style={{
                display: "block",
                marginBottom: "5px",
                fontWeight: "bold",
              }}
            >
              Max Raffle Tickets:
            </label>
            <input
              type="number"
              style={{ width: "100%", padding: "8px", boxSizing: "border-box" }}
            />
          </div>
          <div style={{ width: "100%", marginBottom: "15px" }}>
            <label
              style={{
                display: "block",
                marginBottom: "5px",
                fontWeight: "bold",
              }}
            >
              Start Date and Time:
            </label>
            <input
              type="datetime-local"
              style={{ width: "100%", padding: "8px", boxSizing: "border-box" }}
            />
          </div>
          <div style={{ width: "100%", marginBottom: "15px" }}>
            <label
              style={{
                display: "block",
                marginBottom: "5px",
                fontWeight: "bold",
              }}
            >
              End Date and Time:
            </label>
            <input
              type="datetime-local"
              style={{ width: "100%", padding: "8px", boxSizing: "border-box" }}
            />
          </div>
          <button
            className="btn__purple"
            style={{
              padding: "10px 20px",
              fontSize: "16px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              marginTop: "20px",
            }}
          >
            Create Raffle
          </button>
        </div>
        <Footer />
      </div>
    </Fragment>
  );
};

export default CreateRaffle;
