import React, { Fragment } from "react";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";
import Head from "../../component/Page/Head";

const ProductDetail = () => {
  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="bg__blue-2">
          <Navbar />
          <Head title={"Raffle details"} />
        </div>
        <img
          src="./../images/Subtract.png"
          className="w-100 img-cover"
          alt=""
        />
        <section style={{ paddingTop: "1em" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-6 my-auto">
                <img src="./../products/iphone_15_pro_gold.png" alt="" />
              </div>
              <div className="col-md-6 my-auto">
                <div className="d-flex align-items-center wrapper__profile-player mb-4">
                  <div className="d-flex align-items-start">
                    <h4 className="mb-0 semi-bold font__size--24 text__24-1024 ml-2 align-items-start">
                      iPhone 15 Pro Gold
                    </h4>
                  </div>
                  <a
                    href="#!"
                    className="d-inline-block semi-bold font__size--16 text__16-1024 btn btn__purple px-4 color__white shadow text-capitalize rounded__12 ml-4"
                  >
                    Buy ticket
                  </a>
                </div>

                <h4 className="semi-bold font__size--20 text__20-1024">
                  Raffle details
                </h4>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gridTemplateRows: "1fr 1fr",
                    gap: "10px",
                    padding: "20px",
                  }}
                >
                  <div
                    style={{
                      border: "1px solid #ccc",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    <div style={{ fontWeight: "bold" }}>Start</div>
                    <div>4 July 2024 16:00 UTC</div>
                  </div>
                  <div
                    style={{
                      border: "1px solid #ccc",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    <div style={{ fontWeight: "bold" }}>End</div>
                    <div>8 July 2024 16:00 UTC</div>
                  </div>
                  <div
                    style={{
                      border: "1px solid #ccc",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    <div style={{ fontWeight: "bold" }}>Tickets sold</div>
                    <div>1256</div>
                  </div>
                  <div
                    style={{
                      border: "1px solid #ccc",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    <div style={{ fontWeight: "bold" }}>Max tickets</div>
                    <div>2000</div>
                  </div>
                </div>
                <p>
                  <span style={{ fontWeight: "bold" }}>NOTE:</span>
                  This item requires shipping. Winner pays all shipping costs.
                </p>
                <h4 className="semi-bold font__size--20 text__20-1024">
                  Product details
                </h4>
                <p className="medium font__size--12 text__12-1024 color__gray-1 lh-2 mb-4">
                  The iPhone 15 Pro is the first iPhone to feature an
                  aerospace‑grade titanium design, using the same alloy that
                  spacecraft use for missions to Mars. <br /> A17 Pro chip. A
                  monster win for gaming..{" "}
                </p>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </Fragment>
  );
};

export default ProductDetail;
