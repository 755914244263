import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import CardBlog from "../../component/Card/CardBlog";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";
import Head from "../../component/Page/Head";

const News = () => {
  const blogFeatured = [
    {
      title: "Prep for launch",
      sub: "Dev Update",
      img: "./../images/computer.jpg",
    },
  ];

  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="bg__blue-2">
          <Navbar />

          <Head title={"News"} />
        </div>
        <img
          src="./../images/Subtract.png"
          className="w-100 img-cover"
          alt=""
        />

        <section>
          <div className="container">
            <h2 className="semi-bold font__size--48 text__48-1024 text__48-md text__48-sm text__48-xx mb-3">
              Featured News
            </h2>
            <NavLink
              to="/news/detail"
              className="color__black d-inline-block w-100"
            >
              <img
                src="./../images/ironman.jpg"
                className="images__wrap-featured mb-3"
                alt=""
              />
              <p className="semi-bold font__size--18 text__18-1024 color__blue mb-2">
                Game Update | 1 July 2024
              </p>
              <h4 className="normal font__size--32 text__32-1024 text__32-xs text__32-xxs">
                How SolWin takes raffles on Solana to the next level
              </h4>
            </NavLink>
          </div>
        </section>

        <section>
          <div className="container">
            <h2 className="semi-bold font__size--48 text__48-1024 text__48-md text__48-sm text__48-xx mb-3">
              Popular News
            </h2>

            <div className="row">
              <div className="col-lg-8 mb-4 mb-lg-0">
                <NavLink
                  to="/news/detail"
                  className="color__black d-inline-block w-100 wrapper__card-news"
                >
                  <img src="./../images/team.jpg" className="img mb-3" alt="" />
                  <p className="semi-bold font__size--18 text__18-1024 color__blue mb-2">
                    Team Update | 1 July 2024
                  </p>
                  <h4 className="normal font__size--32 text__32-1024 text__32-xs text__32-xxs">
                    Our backgrounds
                  </h4>
                </NavLink>
              </div>
              <div className="col-lg-4">
                <div className="d-lg-flex h-100 flex-wrap">
                  {blogFeatured.map((obj) => {
                    return (
                      <div className="mb-4">
                        <CardBlog data={obj} />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </Fragment>
  );
};

export default News;
