import React, { Fragment } from "react";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";
import Head from "../../component/Page/Head";

const Privacy = () => {
  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="bg__blue-2">
          <Navbar />
          <Head title={"Privacy Policy"} />
        </div>
        <img
          src="./../images/Subtract.png"
          className="w-100 img-cover"
          alt=""
        />
        <section>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <div className="wrapper__text-wrap">
                  <p className="  normal font__size--18 text__18-1024 color__gray-1 lh-2 mb-5">
                    Last Updated: 07/01/2024 <br />
                    At SolWin, we value and respect your privacy, that's why we
                    are in the cryptocurrency ecosystem! This Privacy Policy
                    outlines how we collect, use, and protect your personal
                    information when you interact with our platform.
                  </p>
                  <div className="mb-5">
                    <h2 className="semi-bold font__size--48 text__48-1024 text__48-md text__48-sm">
                      1. Information We Collect
                    </h2>
                    <p className="  normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      We collect personal information that you provide directly
                      to us, such as your name, email address, address and
                      contact details if you win some physical prize on our
                      platform. In case you link your Twitter account with your
                      Solana wallet, we collect this information in order to
                      distinguish between normal clients and VIP clients, who
                      obtain more benefits if they share SolWin content on
                      Twitter. We also collect information automatically through
                      cookies and other tracking technologies when you use our
                      platform, such as your IP address, browser type, and
                      browsing behavior.
                    </p>
                  </div>
                  <div className="mb-3">
                    <h2 className="semi-bold font__size--48 text__48-1024 text__48-md text__48-sm">
                      2. Use of Information
                    </h2>
                    <p className="  normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      We use your personal information for the following
                      purposes:
                    </p>
                  </div>
                  <div className="mb-5">
                    <ul className="list__normal  normal font__size--18 text__18-1024 color__gray-1">
                      <li>
                        <div className="d-flex align-items-start">
                          <img
                            src="./../images/Frame.png"
                            className="object-fit-content mt-1"
                            alt=""
                          />
                          <div className="ml-2">
                            To administer and manage your account on SolWin.
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-start">
                          <img
                            src="./../images/Frame.png"
                            className="object-fit-content mt-1"
                            alt=""
                          />
                          <div className="ml-2">
                            To process your entries in the raffles and deliver
                            prizes to you.
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-start">
                          <img
                            src="./../images/Frame.png"
                            className="object-fit-content mt-1"
                            alt=""
                          />
                          <div className="ml-2">
                            To improve and personalize your experience on our
                            platform.
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-start">
                          <img
                            src="./../images/Frame.png"
                            className="object-fit-content mt-1"
                            alt=""
                          />
                          <div className="ml-2">
                            To comply with our legal and regulatory obligations.
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="mb-3">
                    <h2 className="semi-bold font__size--48 text__48-1024 text__48-md text__48-sm">
                      3. Sharing Information
                    </h2>
                    <p className="  normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      We do not share, sell, or rent your personal information
                      to third parties, except in the following cases:
                    </p>
                  </div>
                  <div className="mb-5">
                    <ul className="list__normal  normal font__size--18 text__18-1024 color__gray-1">
                      <li>
                        <div className="d-flex align-items-start">
                          <img
                            src="./../images/Frame.png"
                            className="object-fit-content mt-1"
                            alt=""
                          />
                          <div className="ml-2">
                            When necessary to comply with laws, regulations, or
                            legal processes.
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-start">
                          <img
                            src="./../images/Frame.png"
                            className="object-fit-content mt-1"
                            alt=""
                          />
                          <div className="ml-2">
                            In the event of a merger, acquisition, or sale of
                            assets, where your information may be transferred as
                            part of the business.
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="mb-3">
                    <h2 className="semi-bold font__size--48 text__48-1024 text__48-md text__48-sm">
                      4. Information Security
                    </h2>
                    <p className="  normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      We implement appropriate security measures to protect your
                      personal information against unauthorized access,
                      alteration, disclosure, or destruction. However, we cannot
                      guarantee the absolute security of information transmitted
                      over the Internet.
                    </p>
                  </div>
                  <div className="mb-3">
                    <h2 className="semi-bold font__size--48 text__48-1024 text__48-md text__48-sm">
                      5. Your Rights
                    </h2>
                    <p className="  normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      You have the right to access, correct, or delete your
                      personal information. You can also opt out of receiving
                      promotional communications from us at any time. To
                      exercise these rights, please contact us at
                      info@sol-win.io with Subject: Privacy Policy
                    </p>
                  </div>
                  <div className="mb-3">
                    <h2 className="semi-bold font__size--48 text__48-1024 text__48-md text__48-sm">
                      6. Changes to the Privacy Policy
                    </h2>
                    <p className="  normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      We may update this Privacy Policy from time to time. We
                      will notify you of any significant changes by posting the
                      new policy on our platform and updating the "Last Updated"
                      date at the top of this page.
                    </p>
                  </div>
                  <div className="mb-3">
                    <h2 className="semi-bold font__size--48 text__48-1024 text__48-md text__48-sm">
                      7. Contact Us
                    </h2>
                    <p className="  normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      If you have any questions or concerns about this Privacy
                      Policy, please feel free to contact us at info@sol-win.io
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </Fragment>
  );
};

export default Privacy;
