import React, { Fragment } from "react";
import CardProduct from "../../component/Card/CardProduct";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";
import SecBlog from "../../component/Page/SecBlog";

const Index = (props) => {
  const products = [
    "./../products/iphone_15_pro_gold.png",
    "./../products/iPad_pro_black.jpg",
    "./../products/AirPods_pro_2nd_generation.jpg",
    "./../products/iWatch_ultra_2_orange.png",
  ];

  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="bg__blue-2">
          <Navbar />

          <section className="position-relative">
            <img
              src="./../images/hero1.png"
              className="images__head-top d-none d-lg-block"
              alt=""
              style={{ maxHeight: "750px", width: "auto" }}
            />
            <div className="container position-relative z-2">
              <div className="row">
                <div className="col-lg-6 my-auto">
                  <h1 className="semi-bold font__size--72 text__70-1024 text__70-md text__70-sm mb-0">
                    Discover a New Way to Enjoy Raffles!
                  </h1>
                  <p className="medium font__size--12 text__12-1024 color__gray-1 lh-2 my-4">
                    It is time to spice up the raffle experience. We are
                    breaking down the walls and limitations. Raffles for
                    anything!
                  </p>

                  <div className="d-flex flex-wrap flex-md-nowrap align-items-center pt-md-5">
                    <div className="wrapper__cicle-user d-flex align-items-center mb-3 mb-md-0">
                      <div className="items">
                        <img src="./../team/fredicci.png" alt="" />
                      </div>
                      <div className="items">
                        <img src="./../team/preto.jpg" alt="" />
                      </div>

                      <div className="items">
                        <img src="./../team/josan.jpg" alt="" />
                      </div>
                      <div className="items">
                        <img src="./../team/daniel.jpg" alt="" />
                      </div>
                    </div>
                    <span className="ml-2 semi-bold font__size--20 text__20-1024">
                      Building better
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <img
          src="./../images/Subtract.png"
          className="w-100 img-cover"
          alt=""
        />

        <section className="position-relative">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 position__initial">
                <img
                  src="./../images/team3.png"
                  className="images__about-left"
                  alt=""
                />
              </div>
              <div className="col-lg-6">
                <h2 className="semi-bold font__size--72 text__70-1024 text__70-md text__70-sm mb-4">
                  We Are Solana Win!
                </h2>
                <p className="medium font__size--12 text__12-1024 color__gray-1 lh-2">
                  SolWin is a project born from the enthusiasm and passion for
                  cryptocurrencies of three friends united by a common dream. It
                  all started in early 2021, when a group of Solana enthusiasts
                  came together, forming a friendship that has grown and
                  strengthened day by day, making us the team we are today. For
                  over a year, we have been working on an innovative and unique
                  idea within the Solana ecosystem. The challenge was enormous,
                  as hundreds of new projects emerge every day. But that didn't
                  stop us; on the contrary, it motivated us to create something
                  truly special.
                </p>
                <p className="medium font__size--12 text__12-1024 color__gray-1 mb-4 lh-2">
                  <span style={{ fontWeight: "bold" }}>What is SolWin?</span>{" "}
                  SolWin is a platform that offers raffles for cutting-edge tech
                  gadgets: smartphones, tablets, smartwatches, gaming consoles,
                  headphones, and much more. And that's not all: in the near
                  future, we will also include raffles for web3 digital products
                  like NFTs and crypto bags.
                </p>
                <p className="medium font__size--12 text__12-1024 color__gray-1 mb-4 lh-2">
                  <span style={{ fontWeight: "bold" }}>
                    Transparency and Trust
                  </span>{" "}
                  Transparency is our cornerstone. Thanks to the Solana
                  blockchain, all our raffles are completely auditable and
                  secure. The advanced technology of this network allows us to
                  offer you an unparalleled experience, with minimal costs and
                  extraordinary speed. Additionally, SolWin uses the latest
                  Solana technology, blinks are welcome to make everything even
                  more efficient!
                </p>
                <p className="medium font__size--12 text__12-1024 color__gray-1 mb-4 lh-2">
                  <span style={{ fontWeight: "bold" }}>
                    Prizes You'll Love At SolWin
                  </span>{" "}
                  We offer the latest generation tech gadgets from the best
                  brands, completely new and with total warranty for the
                  winners. And if for some reason we can't deliver the prize in
                  person or your home? Don't worry, we will send the equivalent
                  value directly to your wallet, so you can purchase it at your
                  favorite local store!
                </p>
                <p className="medium font__size--12 text__12-1024 color__gray-1 mb-4 lh-2">
                  <span style={{ fontWeight: "bold" }}>Our Commitment</span> We
                  have put all our enthusiasm and effort into this project, and
                  we hope you enjoy this new adventure as much as we do. We are
                  here to offer you a unique, exciting, and rewarding
                  experience.
                </p>
                <p className="medium font__size--12 text__12-1024 color__gray-1 mb-4 lh-2">
                  Join SolWin and be part of this technological revolution!
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="d-flex align-items-center justify-content-center justify-content-md-between mb-5">
              <h2 className="semi-bold font__size--48 text__48-1024 text__48-md text__48-sm text__48-xx">
                Choose Your Prize
              </h2>
            </div>

            <div className="row">
              {products.map((obj) => {
                return (
                  <div className="col-md-6 col-lg-3 mb-4 mb-lg-0">
                    <CardProduct data={obj} />
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        <section className="position-relative">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 order-lg-1 order-12">
                <h2 className="semi-bold font__size--64 text__65-1024 text__65-md text__65-sm mb-4">
                  SolWin: Get Ready for an Epic Raffle Experience!
                </h2>
                <p className="medium font__size--12 text__12-1024 color__gray-1 lh-2">
                  Experience the excitement of participating in raffles for IRL
                  and digital items.
                </p>
              </div>
              <div className="col-lg-6 order-1 order-lg-12 position__initial">
                <img
                  src="./../images/fun2.png"
                  className="images__about-right"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>

        <br></br>
        <br></br>
        <SecBlog title={"Our Latest News"} showMore={true} />

        <Footer />
      </div>
    </Fragment>
  );
};

export default Index;
