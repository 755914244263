import React, { Fragment } from "react";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";
import Head from "../../component/Page/Head";

const Terms = () => {
  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="bg__blue-2">
          <Navbar />

          <Head title={"Terms of Conditions"} />
        </div>
        <img
          src="./../images/Subtract.png"
          className="w-100 img-cover"
          alt=""
        />

        <section>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <div className="wrapper__text-wrap">
                  <div className="mb-5">
                    <h2 className="semi-bold font__size--48 text__48-1024 text__48-md text__48-sm">
                      General Information
                    </h2>
                    <p className=" normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      The raffles will automatically end when all tickets are
                      sold before the set time, or at the set time if there is
                      more than 80% participation. In both cases, a winner will
                      be chosen at random from all participants. If, after the
                      raffle time has elapsed, less than 80% participation has
                      been reached, the tickets will be automatically refunded
                      to the buyers.{" "}
                    </p>
                  </div>

                  <div className="mb-5">
                    <h3 className="semi-bold font__size--32 text__32-1024 text__32-xs">
                      Terms
                    </h3>
                    <p className=" normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      Tickets are non-refundable if the raffle is held. Refunds
                      will only be given to users if less than 80% of the raffle
                      tickets are sold. In these cases, users must claim the
                      ticket amount, and it will be returned to their original
                      wallets.{" "}
                    </p>
                    <p className=" normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      The winner will have 30 days to claim their prize;
                      otherwise, it will be forfeited. The prize will only be
                      sent beyond the 30 days if the user can demonstrate an
                      incident beyond the project's control, such as Solana
                      outages, SolWin website server crashes, or other
                      verifiable errors. In such cases, the team will decide
                      based on the incidents claimed by the winner.{" "}
                    </p>
                    <p className=" normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      SolWin will make every effort to acquire the product in
                      the winner's home country or, failing that, the closest
                      geographical area. If there is no guarantee that the
                      winner can receive the prize, the team will contact them
                      to offer the prize amount in USDC and send it directly to
                      their wallet, allowing the winner to purchase the prize
                      from their local store if they wish.{" "}
                    </p>
                    <p className=" normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      Wallets that purchase the most tickets will receive
                      benefits such as extra tickets in featured raffles.{" "}
                    </p>
                    <p className=" normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      Users who link their wallet accounts with their X
                      (Twitter) accounts will receive extra benefits such as
                      access to exclusive raffles and greater rewards.{" "}
                    </p>
                    <p className=" normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      An extraordinary raffle will be held on the last Sunday of
                      each month, featuring not just one prize, it will be 5 or
                      more prizes.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </Fragment>
  );
};

export default Terms;
