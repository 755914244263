import React, { Fragment, useState } from "react";
import { IconContext } from "react-icons";
import { FaTwitter } from "react-icons/fa";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";

const Team = () => {
  const charcters = [
    "./../team/fredicci.png",
    "./../team/preto.jpg",
    "./../team/josan.jpg",
    "./../team/daniel.jpg",
  ];

  const charctersBig = [
    "./../team/fredicci.png",
    "./../team/preto.jpg",
    "./../team/josan.jpg",
    "./../team/daniel.jpg",
  ];

  const about = [
    {
      bg: "./../team/fredicci.png",
      profile: "./../team/fredicci.png",
      name: "Fredicci",
      desc: "I am 40 years old, I am a fan of technology, the crypto world and music. I have always thought about being able to bridge the crypto world with real life and I am very excited to be able to create this project with my friends in the Solana network. For me, in the not too distant future, Solana will be used in real life for multiple tasks, so I would like to contribute my grain of sand and have great acceptance by all of you. Finally, just say that SolWin is made with a lot of love and enthusiasm.",
      power: ["Visionary", "Resilient"],
      twitter: "https://twitter.com/Fredicci",
    },
    {
      bg: "./../team/preto.jpg",
      profile: "./../team/preto.jpg",
      name: "Preto",
      desc: "I’m 42 years old and I’m an enthusiast of the crypto world and everything related to web3. I have been in the crypto space for more than five years, always reading, learning, training, losing, winning, looking for ways to improve both spiritually and mentally. We were developing a project for a long time, but we were missing some pieces of the puzzle, now we have them all and we can start walking. I am very happy to start this project with my close friends, making Solana have more diversity of projects that rely on it, thanks to its technology, only possible on Solana!",
      power: ["Charismatic", "Compassionate"],
      twitter: "https://twitter.com/praetorivm",
    },
    {
      bg: "./../team/josan.jpg",
      profile: "./../team/josan.jpg",
      name: "Josan",
      desc: "I’m 38 years old, I have always had a passion for technology and innovation. Despite not having dedicated myself professionally to this sector, I have always maintained a relationship with it, either as a hobby or simply for knowledge. Due to the evolution of cryptocurrencies and blockchain technology, I have tried to engage in this new world while maintaining a clear vision: democratize access to this technology. That’s why when two friends (web3 and blockchain enthusiasts) told me about an idea they had, I couldn’t resist and wanted to jump in. This idea was to create a platform that uses the Solana blockchain to organize raffles. SolWin is differentiated by its transparency and efficiency, thanks to the unique characteristics of the Solana network, such as its high transaction speed and low operating costs. The goal is to create a platform that also educates users about the benefits of blockchain and fosters a global technology community.",
      power: ["Tenacious", "Innovative"],
      twitter: "https://twitter.com/josan6sanchez",
    },
    {
      bg: "./../team/daniel.jpg",
      profile: "./../team/daniel.jpg",
      name: "Daniel Charpentier",
      desc: "working to delight users & learn ♥️ #golang #python #javascript ",
      power: ["Won't stop", "Can't stop"],
      twitter: "https://twitter.com/daniel_charp",
    },
  ];

  const [character, setCharacter] = useState(0);
  const [characterBig, setCharacterBig] = useState(0);
  const [characterAbout, setCharacterAbout] = useState(0);

  const onChangeClick = (e) => {
    setCharacter(e);
    setCharacterBig(e);
    setCharacterAbout(e);
  };

  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="bg__blue-2">
          <Navbar />

          <section className="position-relative">
            <div className="d-none d-lg-block">
              
            </div>

            <div className="container position-relative z-2">
              <div className="row">
                <div className="col-lg-5 z-2" style={{ textAlign: 'center' }}>
                {charctersBig.map((obj, i) => {
                if (i === characterBig) {
                  return (
                    <img src={obj} className="images__character-side" alt="" />
                  );
                }
              })}
                </div>
                <div className="col-lg-7 z-2">
                  <div className="mb-5">
                    <div className="wrapper__offsite-character">
                      <div className="auto">
                        <div className="wrapper__characters-lits">
                          {charcters.map((obj, i) => {
                            return (
                              <div className="items">
                                <div
                                  className={
                                    "position-relative wrapper__images-icon text-center pointer " +
                                    (character === i ? "active" : "")
                                  }
                                  onClick={() => onChangeClick(i)}
                                >
                                  <img src={obj} alt="" />
                                  <div className="dot mx-auto mt-3"></div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>

                  {about.map((obj, i) => {
                    if (i === characterAbout) {
                      return (
                        <div className="wrapper__charter-detail">
                          <div className="desc ml-3 ml-sm-5">
                            <div className="user d-flex align-items-center mb-3">
                              <h5 className="mb-0 semi-bold font__size--24">
                                {obj.name}
                              </h5>
                            </div>
                            <h4 className="semi-bold font__size--20">
                              About me
                            </h4>
                            <p className="medium font__size--12 color__gray-1 mb-4">
                              {obj.desc}
                            </p>
                            <h4 className="semi-bold font__size--20">
                              My Powers
                            </h4>
                            <div className="d-flex flex-wrap tag">
                              {obj.power.map((k) => {
                                return (
                                  <div className="items normal font__size--12">
                                    {k}
                                  </div>
                                );
                              })}
                            </div>
                            <div style={{ paddingTop: '1em' }}>
                              <a
                                href={obj.twitter}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <IconContext.Provider
                                  value={{ color: "#8682E3", size: "2em" }}
                                >
                                  <div>
                                    <FaTwitter />
                                  </div>
                                </IconContext.Provider>
                              </a>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </section>
        </div>
        <img
          src="./../images/Subtract.png"
          className="w-100 img-cover"
          alt=""
        />

        <Footer />
      </div>
    </Fragment>
  );
};

export default Team;
